
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ApiStore } from "@/core/api";
import { setModuleBCN } from "@/core/directive/function/common";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "stores-overview",
  components: {},
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();

    const loading = ref<boolean>(true);

    const formData = ref({
      status: 10,
      name: "",
      short_key: "",
      email: "",
      website: "",
      telephone: "",
      telephone2: "",
      fax: "",
      address: "",
      zip_code: "",
      city: "",
      country_iso_2: "",
      overall_notes: "",
      merchant: "",
    });

    const getPosData = () => {
      ApiStore.getStoreInfo({ id: route.params.id })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            formData.value = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    onMounted(() => {
      setModuleBCN(t, route, router);
      getPosData();
    });

    return {
      loading,
      formData,
      getPosData,
    };
  },
});
